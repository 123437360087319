import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import { Alert, IconButton, InputAdornment, Snackbar, Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/iconify";
import { makeAPIRequest } from "../../../utils/apiHandler";
import { AuthSignupUserBody, NoParams } from "../../../utils/apiRequests";
import { APIResponseData, AuthAltLoginResponse } from "../../../utils/apiResponses";
import { useInvalidFields } from "../../../utils/hooks";
import { AUTH_TOKEN_LS_NAME } from "../../../utils/commonUtils";

// ----------------------------------------------------------------------

export default function SignupForm() {
	const navigate = useNavigate();

	const [showPassword, setShowPassword] = useState(false);
	const [userMail, setUserMail] = useState<string>("");
	const [userPassword, setUserPassword] = useState<string>("");
	const [confirmPassword, setConfirmPassword] = useState<string>("");
	const [userContact, setUserContact] = useState<string>("");
	const [fullName, setFullName] = useState<string>("");

	const { setInvalidFields, invalidFields, fieldText } = useInvalidFields<AuthSignupUserBody>({
		removeDuplicates: true,
		fieldMapping: {
			userMail: "E-Mail Address",
			userPassword: "Password",
			userContact: "Phone Number",
			userFullName: "Name",
			userType: "Data"
		},
		defaultValue: {
			userType: false,
			userPhotoObjectKey: false,
			userPassword: true,
			userMail: true,
			userFullName: true,
			userContact: true
		},
		fieldTextGenFn: (invalidFields, fieldMapping) => {
			const mappedFields = invalidFields.map((fieldName) => {
				return fieldMapping[fieldName];
			});

			console.log("mapped fields",mappedFields.join(", "));
			

			if(mappedFields.join(", ") === "Password") 
				return "Please enter more than 8 digit password!";


			return "Invalid " + mappedFields.join(", ") + " entered!";
		}
	});

	const handleClick = useCallback(async () => {
		if (userPassword.length < 8){
			setAlertData({
				alertText: "Please enter characters 8 digit long",
				alertType: "error",
				showAlert: true
			})
		}

		const { isSuccess, isError, responseCode, responseData, requestError } = await makeAPIRequest<
			AuthAltLoginResponse,
			NoParams,
			AuthSignupUserBody,
			NoParams
		>({
			requestEndpoint: "/auth/signup",
			requestMethod: "POST",
			bodyParams: {
				userType: "SERVICE",
				userContact: userContact,
				userFullName: fullName,
				userMail: userMail,
				userPassword: userPassword
			}
		});
		if (isSuccess) {
			const { responseStatus } = responseData;
			if (responseStatus === "SUCCESS") {
				const {authToken} = responseData
				localStorage.setItem(AUTH_TOKEN_LS_NAME, authToken)
				navigate("/");
			}
			if (responseStatus === "ERR_MISSING_BODY_PARAMS" || responseStatus === "ERR_INVALID_BODY_PARAMS") {
				const mixedParams = [
					...(responseData?.invalidParams || []),
					...(responseData?.missingParams || [])
				] as (keyof AuthSignupUserBody)[];

				const clonedInvalid = {
					...invalidFields
				};

				for (const mixedParam of mixedParams) {
					// @ts-ignore
					clonedInvalid[mixedParam as keyof AuthSignupUserBody] = true;
				}

				setInvalidFields(clonedInvalid);
				setAlertData((prevData) => {
					return {
						...prevData,
						showAlert: true
					};
				});
			}
			if (responseStatus === "ERR_DUPLICATE_DATA") {
				setAlertData({
					alertText: "Contact or E-Mail already in use!",
					showAlert: true,
					alertType: "error"
				});
			}
		}
	}, [fullName, invalidFields, navigate, setInvalidFields, userContact, userMail, userPassword]);

	const [alertData, setAlertData] = useState<{
		alertText: string;
		showAlert: boolean;
		alertType: "error" | "success";
	}>({
		alertText: "",
		showAlert: false,
		alertType: "error"
	});

	const anyFieldInvalid = Object.values(invalidFields).some((invalidField) => {
		// @ts-ignore
		return invalidField === true;
	});

	return (
		<>
			<Stack spacing={2} sx={{ mb: 2 }}>
				<TextField
					name="fullname"
					label="Your Name"
					type={"text"}
					error={invalidFields.userFullName}
					onChange={(e) => {
						setInvalidFields({
							...invalidFields,
							userFullName: false
						});
						setFullName(e.target.value);
					}}
				/>

				<TextField
					name="email"
					label="Email Address"
					type={"email"}
					error={invalidFields.userMail}
					onChange={(e) => {
						setInvalidFields({
							...invalidFields,
							userMail: false
						});
						setUserMail(e.target.value);
					}}
				/>

				<TextField
					name="contact"
					label="Phone Number"
					type="number"
					inputProps={{
						inputMode: "numeric",
						pattern: "[0-9]{10,}"
					}}
					value={userContact}
					error={invalidFields.userContact}
					onChange={(e) => {
						setInvalidFields({
							...invalidFields,
							userContact: false
						});
						if (e.target.value.length <= 10) {
							setUserContact(e.target.value);
						}
					}}
				/>

				<TextField
					name="password"
					label="Password"
					type={showPassword ? "text" : "password"}
					error={invalidFields.userPassword}
					onChange={(e) => {
						setInvalidFields({
							...invalidFields,
							userPassword: false,
							confirmPassword: e.target.value !== confirmPassword
						});
						setUserPassword(e.target.value);
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
									{/* @ts-ignore */}
									<Iconify icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"} />
								</IconButton>
							</InputAdornment>
						)
					}}
				/>

				<TextField
					name="password"
					label="Confirm Password"
					type={"password"}
					error={invalidFields.confirmPassword}
					onChange={(e) => {
						setInvalidFields({
							...invalidFields,
							confirmPassword: e.target.value !== userPassword
						});
						setConfirmPassword(e.target.value);
					}}
				/>
			</Stack>

			<LoadingButton
				fullWidth
				size="large"
				type="submit"
				variant="contained"
				onClick={handleClick}
				disabled={anyFieldInvalid}
			>
				Sign Up
			</LoadingButton>

			<Snackbar
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center"
				}}
				autoHideDuration={10000}
				open={alertData.showAlert}
				onClose={() => {
					setAlertData((prevState) => {
						return {
							...prevState,
							showAlert: false
						};
					});
				}}
			>
				<Alert severity={fieldText ? alertData.alertType : "error"} variant={"filled"}>
					{fieldText ? fieldText : alertData.showAlert ? alertData.alertText : "An error occurred!"}
				</Alert>
			</Snackbar>
		</>
	);
}
