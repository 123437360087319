// @ts-ignore
import { useCallback, useEffect, useMemo, useState } from "react";
import { APIResponseData, APIStatusCode } from "./apiResponses";
import { AUTH_TOKEN_LS_NAME } from "./commonUtils";

// export const API_HOST = "https://api.maangalbazaar.com";
// export const API_HOST="http://localhost:8051"
// export const API_HOST = "https://bazarapi.ctoninja.tech";
 export const API_HOST = 'https://t-api.maangalbazaar.com';

export const API_VERSION = "v1";

type APIRequestArgs<Param, Body, Query> = {
	requestEndpoint: string;
	requestMethod: "GET" | "PUT" | "POST" | "DELETE";
	urlParams?: Param;
	queryParams?: Query;
	bodyParams?: Body;
};

type APIRequestResponse<ResponseT extends APIResponseData> =
	| {
			isSuccess: true;
			isError: false;
			responseCode: APIStatusCode;
			responseData: ResponseT;
			requestError: undefined;
	  }
	| {
			isSuccess: false;
			isError: true;
			responseCode: 0 | -1;
			responseData: undefined;
			requestError: Error;
	  }
	| {
			isSuccess: false;
			isError: false;
			responseCode: -1;
			responseData: undefined;
			requestError: undefined;
	  };

/**
 * @type makeAPIRequest
 * */
export async function makeAPIRequest<
	ResponseT extends APIResponseData = APIResponseData,
	RequestParamsT extends {} = {},
	RequestBodyT extends {} = {},
	RequestQueryT extends {} = {}
>(args: APIRequestArgs<RequestParamsT, RequestBodyT, RequestQueryT>): Promise<APIRequestResponse<ResponseT>> {
	try {
		let { requestEndpoint, requestMethod, urlParams, queryParams, bodyParams } = args;

		const urlParamKeys = Object.keys(urlParams || {});

		urlParamKeys.forEach((paramKey) => {
			// @ts-ignore
			const paramValue = urlParams[paramKey];
			requestEndpoint = requestEndpoint.replace(`:${paramKey}`, encodeURIComponent(paramValue));
		});

		const apiUrl = new URL(
			`/api/${API_VERSION}/${requestEndpoint.startsWith("/") ? requestEndpoint.slice(1) : requestEndpoint}`,
			API_HOST
		);

		for (const [queryKey, queryValue] of Object.entries(queryParams || {})) {
			// @ts-ignore
			apiUrl.searchParams.set(queryKey, queryValue.toString());
		}

		const localStorageToken = localStorage.getItem(AUTH_TOKEN_LS_NAME);

		let reqHeaders: HeadersInit = {
			"Content-Type": "application/json"
		};

		if (localStorageToken !== null && localStorageToken.length > 0) {
			reqHeaders = {
				...reqHeaders,
				Authorization: `Bearer ${localStorageToken}`
			};
		}

		const apiResponse = await fetch(apiUrl, {
			headers: reqHeaders,
			credentials: "include",
			cache: "no-cache",
			method: requestMethod,
			body: requestMethod !== "GET" ? JSON.stringify(bodyParams) : undefined
		});

		const responseData = await apiResponse.json();

		return {
			isSuccess: true,
			isError: false,
			responseCode: apiResponse.status as APIStatusCode,
			responseData: responseData,
			requestError: undefined
		};
	} catch (err) {
		console.error(err);
		return {
			isSuccess: false,
			isError: true,
			responseCode: 0,
			responseData: undefined,
			requestError: err as unknown as Error
		};
	}
}

type APIRequestHookArgs<Params, Body, Query> = APIRequestArgs<Params, Body, Query> & {
	requestDeps?: any[];
};

type APIRequestHookResponse<ResponseT extends APIResponseData> = {
	isLoading: boolean;
	isSuccess: boolean;
	isError: boolean;
	responseCode: 0 | -1 | APIStatusCode;
	responseData?: ResponseT;
	requestError?: Error;
};

export function useAPIRequest<
	Response extends APIResponseData,
	Params extends {} = {},
	Body extends {} = {},
	Query extends {} = {}
>(args: APIRequestHookArgs<Params, Body, Query>): APIRequestHookResponse<Response> {
	const {
		requestEndpoint,
		requestMethod = "GET",
		urlParams = {},
		queryParams = {},
		bodyParams = {},
		requestDeps = []
	} = args;

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [responseData, setResponseData] = useState<APIRequestResponse<Response>>({
		isSuccess: false,
		isError: false,
		responseCode: -1,
		requestError: undefined,
		responseData: undefined
	});

	const requestFn = useCallback(async () => {
		setIsLoading(true);
		setResponseData({
			isSuccess: false,
			isError: false,
			responseCode: -1,
			requestError: undefined,
			responseData: undefined
		});
		const responseObject = await makeAPIRequest<Response, Params, Body, Query>(args);
		setResponseData(responseObject);
		setIsLoading(false);
	}, [
		requestEndpoint,
		requestMethod,
		...Object.entries(urlParams),
		...Object.entries(queryParams),
		...Object.entries(bodyParams)
	]);

	useEffect(() => {
		requestFn();
	}, [...requestDeps]);

	return {
		isLoading: isLoading,
		...responseData
	};
}

type PreparedRequestHookArgs<Params, Body, Query> = Omit<APIRequestArgs<Params, Body, Query>, "requestMethod"> & {
	requestMethod: Exclude<APIRequestArgs<Params, Body, Query>["requestMethod"], "GET">;
};

export function usePreparedRequest<
	Response extends APIResponseData,
	Params extends {} = {},
	Body extends {} = {},
	Query extends {} = {}
>(args: PreparedRequestHookArgs<Params, Body, Query>) {
	const { requestEndpoint, requestMethod, urlParams = {}, queryParams = {}, bodyParams = {} } = args;
	const callableFn = useCallback(async () => {
		const responseData = await makeAPIRequest<Response, Params, Body, Query>(args);
		return responseData;
	}, [
		requestEndpoint,
		requestMethod,
		...Object.entries(urlParams),
		...Object.entries(queryParams),
		...Object.entries(bodyParams)
	]);

	return callableFn;
}
