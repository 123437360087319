import { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import { Alert, IconButton, InputAdornment, Link, Snackbar, Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/iconify";
import AuthContext from "../../../utils/authContext";
import { makeAPIRequest } from "../../../utils/apiHandler";
import { AuthLoginUserBody, NoParams } from "../../../utils/apiRequests";
import { APIResponseData, AuthAltLoginResponse } from "../../../utils/apiResponses";
import { useInvalidFields } from "../../../utils/hooks";
import ForgotPasswordModal from "./ForgotPassword";
import { AUTH_TOKEN_LS_NAME } from "../../../utils/commonUtils";
import { loginLoaderSlice } from "../../../redux/slice/loginLoderSlice";
import { useDispatch } from "react-redux";
// ----------------------------------------------------------------------

export default function LoginForm() {
	const dispatch = useDispatch();
	const authCtx = useContext(AuthContext);

	const navigate = useNavigate();

	const [emailAddress, setEmailAddress] = useState("");
	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [loginRequestActive, setLoginRequestActive] = useState(false);

	const { setInvalidFields, invalidFields, fieldText } = useInvalidFields<AuthLoginUserBody>({
		removeDuplicates: true,
		defaultValue: {
			userPassword: true,
			userMail: true
		},
		fieldMapping: {
			userMail: "E-Mail or Phone",
			userPassword: "Password",
			userType: "Login"
		},
		fieldTextGenFn: (invalidFields, fieldMapping) => {
			const mappedFields = invalidFields.map((fieldName) => {
				return fieldMapping[fieldName];
			});

			return "Invalid " + mappedFields.join(" and ") + " entered!";
		}
	});

	const resetInvalidFields = () => {
		return {
			userMail: false,
			userPassword: false
		};
	};

	const handleClick = useCallback(async () => {
		setLoginRequestActive(true);

		const { isSuccess, isError, responseCode, requestError, responseData } = await makeAPIRequest<
			AuthAltLoginResponse,
			NoParams,
			AuthLoginUserBody
		>({
			requestEndpoint: "/auth/login",
			requestMethod: "POST",
			bodyParams: {
				userMail: emailAddress,
				userPassword: password,
				userType: "SERVICE"
			}
		});

		if (isSuccess) {
			const { responseStatus } = responseData;
			if (responseStatus === "SUCCESS") {
				const {authToken} = responseData
				localStorage.setItem(AUTH_TOKEN_LS_NAME, authToken)
				dispatch(loginLoaderSlice.actions.activateLoader())
				navigate("/");
			}
			if (responseStatus === "ERR_INVALID_BODY_PARAMS" || responseStatus === "ERR_MISSING_BODY_PARAMS") {
				const invalidParams = [
					...((responseData.invalidParams || []) as (keyof AuthLoginUserBody)[]),
					...((responseData.missingParams || []) as (keyof AuthLoginUserBody)[])
				];
				setInvalidFields(invalidParams);
				setAlertData((prevData) => {
					return {
						...prevData,
						showAlert: true
					};
				});
			}
		}

		setLoginRequestActive(false);
	}, [emailAddress, navigate, password, setInvalidFields]);

	const [alertData, setAlertData] = useState<{
		showAlert: boolean;
		alertType: "error" | "success";
	}>({
		showAlert: false,
		alertType: "error"
	});

	const [isModalOpen, setIsModalOpen] = useState(false);

	return (
		<>
			<Stack spacing={3}>
				<TextField
					name="email"
					label="Email address or Phone"
					defaultValue={emailAddress}
					error={invalidFields.userMail}
					onChange={(e) => {
						setInvalidFields(resetInvalidFields());
						setEmailAddress(e.target.value);
					}}
				/>

				<TextField
					name="password"
					label="Password"
					type={showPassword ? "text" : "password"}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
									{/* @ts-ignore*/}
									<Iconify icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"} />
								</IconButton>
							</InputAdornment>
						)
					}}
					error={invalidFields.userPassword}
					defaultValue={password}
					onChange={(e) => {
						setInvalidFields(resetInvalidFields());
						setPassword(e.target.value);
					}}
				/>
			</Stack>
			<Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
				<Link
					variant="subtitle2"
					underline="hover"
					sx={{ cursor: "pointer" }}
					onClick={() => setIsModalOpen(true)}
				>
					Forgot password?
				</Link>
			</Stack>
			<LoadingButton
				fullWidth
				size="large"
				type="submit"
				variant="contained"
				onClick={handleClick}
				loading={loginRequestActive}
			>
				Login
			</LoadingButton>
			<ForgotPasswordModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
			<Snackbar
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center"
				}}
				autoHideDuration={10000}
				open={alertData.showAlert}
				onClose={() => {
					setAlertData((prevState) => {
						return {
							...prevState,
							showAlert: false
						};
					});
				}}
			>
				<Alert severity={fieldText ? alertData.alertType : "error"} variant={"filled"}>
					{fieldText ? fieldText : "An error occurred"}
				</Alert>
			</Snackbar>
		</>
	);
}
